import { useEffect, useMemo, useState } from "react";
import cx from "classnames";
import { Navbar, Typography, Button } from "@material-tailwind/react";
import "./styles.scss";
import MobileNavigation from "./components/MobileNavigation";
import MobileNavButton from "./components/MobileNavButton";
import NavList from "./components/NavList";
import taiskLogo from "../../../assets/logo/taisk-logo.svg";
import taiskLogoWhite from "../../../assets/logo/taisk-white.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SubNavMenu from "./components/SubNavMenu";
import { CiSearch } from "react-icons/ci";
import { useSelector } from "react-redux";
import SubMenuDrawer from "./components/SubMenuDrawer";
import { EXTRA_URLs } from "../../../utils/constants";

const MarketplacePages = ["marketplace", "library"];

const Header = () => {
  const { search: defaultSearch } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [search, setSearch] = useState("");
  const [openNav, setOpenNav] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 960);
  const [hideSubNav, setHideSubNav] = useState(true);
  const [hideSearch, setHideSearch] = useState(true);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [subMenu, setSubMenu] = useState(null);
  const closeDrawer = () => setOpenSubMenu(false);
  const isLibraryPage = useMemo(() => {
    return MarketplacePages.some((x) => location.pathname.includes(x));
  }, [location.pathname]);

  const categories = useSelector((state) => state.marketplace.categories);
  const categoriesMenu = useMemo(() => {
    return [
      {
        name: "AI For Schools",
        path: "/for/educators",
        category: "Education",
        roles: categories?.["Education"],
        disabled: false,
      },
      {
        name: "AI For Startups",
        path: "/for/startups",
        category: "Entrepreneurship",
        roles: categories?.["Entrepreneurship"],
        disabled: false,
      },
      {
        name: "AI For Non Profits",
        path: "/for/nonProfits",
        category: "Ai For Non Profits",
        roles: categories?.["Ai For Non Profits"],
        disabled: false,
      },
    ];
  }, [categories, location.pathname]);

  useEffect(() => {
    setSearch(defaultSearch ?? "");
  }, [defaultSearch]);

  useEffect(() => {
    if (typeof window === "undefined") return;
    window.addEventListener("resize", () => {
      setIsMobile(window.innerWidth <= 960);
      if (window.innerWidth <= 960) {
        setOpenNav(false);
        setOpenSubMenu(false);
      }
    });
    window.addEventListener("scroll", () => {
      setHideSubNav(Boolean(window.scrollY <= 20));
      setHideSearch(Boolean(window.scrollY <= 230));
    });
  }, []);

  const handleNavigateHomePage = () => {
    setOpenNav(false);
    setOpenSubMenu(false);
    navigate("/");
    if (!location?.pathname || location?.pathname === "/") {
      window.location.reload();
      return;
    }
  };

  const handleMobileNav = (status) => {
    setOpenNav(status);
    if (!status) setOpenSubMenu(false);
  };

  const handleSubMenuNavigation = (sector) => {
    navigate(`/?sector=${sector}`);
  };

  const isHomePage = useMemo(() => {
    return location.pathname === "/";
  }, [location.pathname]);

  const list = [
    {
      title: "Menu",
      navList: [
        // {
        //   name: "Build and Sell AI",
        //   path: EXTRA_URLs.CREATORS,
        // },
        {
          name: "Using Taisk",
          path: EXTRA_URLs.DEMO,
        },
        {
          name: "Pricing",
          path: "/creators",
        },
        {
          name: "Contact Us",
          path: "/talk-with-us",
        },
      ],
    },
    {
      title: "Categories",
      navList: categoriesMenu,
    },
  ];

  const getNavList = (_title) => {
    return list.find((_i) => _i.title === _title)?.navList || [];
  };

  const handleLogin = () => {
    window.location.href = EXTRA_URLs.LOGIN_PATH;
  };

  const handleGetStarted = () => {
    window.location.href = EXTRA_URLs.GET_STARTED_PATH;
  };

  const handleMainMenuNavigation = ({ name, path }) => {
    switch (name) {
      case "Build and Sell AI":
      case "Using Taisk":
      case "Contact Us":
      case "Taisk Labs":
        window.location.href = path;
        break;
      case "Pricing":
        setOpenNav(false);
        navigate("/#pricing");
        break;
      default:
        navigate(path);
    }
  };

  const handleMobileSubMenu = (url) => {
    setOpenNav(false);
    navigate(url);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (!search) return;
    navigate(`/search/library/${search}`);
  };

  return (
    <>
      <Navbar
        className={
          "header-navbar-wrapper bg-opacity-100 fixed top-0 h-max max-w-full rounded-none p-0 " +
          (hideSubNav && !isMobile && isHomePage ? "transparent" : "")
        }
      >
        <div className="header-border">
          <div className="container mx-auto sm:px-0 px-5 header-navbar-container flex items-center justify-between text-blue-gray-900 py-2 lg:py-4">
            <div className="flex flex-1 xl:pr-16 lg:pr-8 pr-6">
              <Typography
                as="a"
                onClick={() => handleNavigateHomePage()}
                className="mr-10 cursor-pointer py-1.5 font-medium header-title min-w-[73px]"
              >
                <img
                  className={
                    "text-logo " +
                    (hideSubNav && !isMobile && isHomePage ? "white" : "")
                  }
                  src={
                    hideSubNav && !isMobile && isHomePage
                      ? taiskLogoWhite
                      : taiskLogo
                  }
                  alt="taisk-logo"
                />
              </Typography>

              <form
                className={
                  "search-input " +
                  (hideSearch && !isMobile && isHomePage ? "hidden" : "block")
                }
                onSubmit={handleSearch}
              >
                <input
                  type="text"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <button type="submit">
                  <CiSearch size={20} />
                </button>
              </form>
            </div>

            <div className="flex-1 items-center gap-4 hidden lg:flex">
              <div className="mr-4">
                <NavList
                  list={getNavList("Menu")}
                  isTop={hideSubNav && !isMobile && isHomePage}
                  navigate={handleMainMenuNavigation}
                />
              </div>
              <div className="sign-section flex items-center gap-x-1">
                <Button
                  variant="gradient"
                  size="sm"
                  className="hidden lg:inline-block login-btn"
                  onClick={handleLogin}
                >
                  <span>Login</span>
                </Button>
                <Button
                  variant="gradient"
                  size="sm"
                  className="hidden lg:inline-block get-start-btn"
                  onClick={handleGetStarted}
                >
                  <span className="text-nowrap">Get Started</span>
                </Button>
              </div>
            </div>
            <MobileNavButton isOpen={openNav} action={handleMobileNav} />
          </div>
        </div>
        <div
          className={cx(
            "container mx-auto sm:px-0 px-5 hidden lg:block header-navbar-container sub-navbar flex items-center justify-between text-blue-gray-900 gap-4",
          )}
          style={{
            display: !isMobile
              ? isHomePage
                ? !hideSubNav && !isMobile
                  ? "flex"
                  : "none"
                : "flex"
              : "none",
          }}
        >
          <div className="mx-auto hidden lg:flex w-full">
            <div className="nav-list w-full justify-around mt-2 mb-4 flex flex-col gap-1 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-0.5">
              {getNavList("Categories")?.map((_link, idx) => (
                <SubNavMenu
                  idx={idx}
                  showDefault={isLibraryPage}
                  path={_link?.path}
                  category={_link.category || _link?.name}
                  name={_link?.name}
                  key={idx}
                  roles={_link?.roles}
                  disabled={_link?.disabled}
                />
              ))}
            </div>
          </div>
        </div>
        <MobileNavigation
          open={openNav}
          list={list}
          navigate={handleMainMenuNavigation}
          setSubMenu={setOpenSubMenu}
          setMenu={setSubMenu}
          setOpenNav={setOpenNav}
        />
      </Navbar>
      {/* <SubMenuDrawer
        openSubMenu={openSubMenu}
        closeDrawer={closeDrawer}
        subMenu={subMenu}
        navigate={handleMobileSubMenu}
      /> */}
    </>
  );
};

export default Header;
